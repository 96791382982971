import React from "react"

/**
 * Use this component to render simple file dialog which can locally read selected user file.
 * Usage: <LocalFileUploader onload={(input) => { ... } onerror={(error) => {...} }>
 */
class LocalFileUploader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            onbegin: props.onbegin,
            onload: props.onload,
            onerror: props.onerror
        }
    }

    readFile(event) {
        const input = event.target
        const state = this.state

        // get only first file
        let file = input.files[0];

        state.onbegin(file)

        // create FileReader instance for local reading of the file
        let reader = new FileReader();

        // try to read contents
        reader.readAsText(file);

        reader.onload = function () {
            state.onload(file, reader.result)
        };

        reader.onerror = function () {
            state.onerror(file, reader.error);
        };
    }

    render() {
        return (
            <input type="file" onChange={this.readFile.bind(this)}>
            </input>
        )
    }

}

export default LocalFileUploader