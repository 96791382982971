import "./SocialMediaInfo.css"
import React from "react"

import FacebookSvg
    from '../../resources/icons/social_media/5279111_network_fb_social media_facebook_facebook logo_icon.svg'
import InstagramSvg
    from '../../resources/icons/social_media/5279112_camera_instagram_social media_instagram logo_icon.svg'
import YoutubeSvg from '../../resources/icons/social_media/5279120_play_video_youtube_youtuble logo_icon.svg'
import VkSvg from '../../resources/icons/social_media/7693329_vk_social media_logo_icon.svg'
import TelegramSvg from '../../resources/icons/social_media/4875780_telegram_icon.svg'

// element types
const SocialMediaType = Object.freeze({
    FACEBOOK: {
        name: "facebook",
        url: "https://www.facebook.com/brcrocket/",
        visibleUrl: "facebook.com/brcrocket/",
        cssClass: "SocialMediaButton-facebook",
        Svg: FacebookSvg
    },
    INSTAGRAM: {
        name: "instagram",
        url: "https://www.instagram.com/b_rocketry/",
        visibleUrl: "instagram.com/b_rocketry/",
        cssClass: "SocialMediaButton-instagram",
        Svg: InstagramSvg
    },
    YOUTUBE: {
        name: "youtube",
        url: "https://www.youtube.com/channel/UCfpTvmF-eA9LRZ7-oqn_n-w",
        visibleUrl: "youtube.com/channel/gria_tech",
        cssClass: "SocialMediaButton-youtube",
        Svg: YoutubeSvg
    },
    VK: {
        name: "vk",
        url: "https://vk.com/b_rocketry",
        visibleUrl: "vk.com/b_rocketry",
        cssClass: "SocialMediaButton-vk",
        Svg: VkSvg
    },
    TELEGRAM: {
        name: "telegram",
        url: "https://t.me/griatech",
        visibleUrl: "t.me/griatech",
        cssClass: "SocialMediaButton-telegram",
        Svg: TelegramSvg
    }
});

// private React element
const SocialMediaButton = (props) => {
    let vUrl;
    if (props.display === "full") {
        vUrl = props.type.visibleUrl;
    }
    return <div className={`SocialMediaButton-button ${props.type.cssClass}`}>
        <a href={props.type.url} target="_blank" className="App-no-decoration">
            <div className="SocialMediaButton-vcenter-icon-and-visible-url">
                <img className={"SocialMediaButton-icon-size"}
                     src={props.type.Svg}
                     alt={props.type.name}
                     height="128"
                     width="128"/>
                <span>&nbsp;{vUrl}</span>
            </div>
        </a>
    </div>
}


export const SocialMediaInfo = (props) => {
    return (
        <div className="SocialMediaInfo-container">
            <h3><span className={"SocialMediaButton-caption"}>{props.caption}</span></h3>
            <div className="SocialMediaInfo-button-container">
                <SocialMediaButton type={SocialMediaType.INSTAGRAM}/>
                <SocialMediaButton type={SocialMediaType.FACEBOOK}/>
                <SocialMediaButton type={SocialMediaType.YOUTUBE}/>
                <SocialMediaButton type={SocialMediaType.VK}/>
            </div>
        </div>
    )
}

export const ContactsInfo = (props) => {
    return (
        <div className="ContactsInfo-root">
            <div className="ContactsInfo-button-holder">
                <SocialMediaButton display="full" type={SocialMediaType.INSTAGRAM}/>
                <SocialMediaButton display="full" type={SocialMediaType.FACEBOOK}/>
                <SocialMediaButton display="full" type={SocialMediaType.VK}/>
                <SocialMediaButton display="full" type={SocialMediaType.TELEGRAM}/>
                <SocialMediaButton display="full" type={SocialMediaType.YOUTUBE}/>
            </div>
        </div>
    )
}

export default SocialMediaInfo