export function getPagePosition(element){
    let boundingRect = element.getBoundingClientRect()
    var x=0;
    var y=0;
    while(true){
        x += element.offsetLeft;
        y += element.offsetTop;
        if(element.offsetParent === null){
            break;
        }
        element = element.offsetParent;
    }
    return [x, y, boundingRect.height, boundingRect.width];
}
