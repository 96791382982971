import React, {useState} from "react";

import './fiveGraphicsDrawer/LocalFileDrawer.css';
import {FileList, uploadFileListPromise} from "./FileList";
import {DropzoneS3FileUploader} from "../file/DropzoneS3FileUploader";
import {getReadableFileSizeString} from "./fiveGraphicsDrawer/utils/insight200_parser";

export const FileManager = () => {
    let [fileListState, setFileListState] = useState({fileList: [], updated: false})
    if (!fileListState.updated) {
        uploadFileListPromise("/api/v1/files/measurement_file/").then(newFileList => {
            setFileListState(p => {
                return {...p, updated: true, fileList: newFileList || []}
            })
        }, console.error);
    }

    let [userState, setUserState] = useState({max_count: 0, max_size: 0, updated: false})
    if (!userState.updated) {
        fetch("/api/v1/user").then(res => res.json()).then(
            json => {
                setUserState(p => {
                    return {
                        ...p,
                        updated: true,
                        max_count: json['max_allowed_file_count'] || 0,
                        max_size: json['max_allowed_file_size_bytes']
                    }
                })
            },
            console.error
        )
    }

    return (
        <div className="FileManager-root">
            <p>
                <span hidden={!userState.updated || !fileListState.updated}>
                    Uploaded {fileListState.fileList.length}/{userState.max_count} files.
                </span> &nbsp;
                <span hidden={!userState.updated}>
                    Maximum available file size: {getReadableFileSizeString(userState.max_size)}.
                </span>
            </p>
            <DropzoneS3FileUploader onUpload={result => {
                setFileListState(p => {
                    return {...p, fileList: [], updated: false}
                })
            }}/>
            <div className="FileManager-file-roaster-root">
                <FileList fileList={fileListState.fileList} onDelete={
                    (file) => setFileListState({fileList: [], updated: false})
                }/>
            </div>
        </div>
    )
}

export default FileManager