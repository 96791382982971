import React from "react"
import Plot from 'react-plotly.js';

// see https://stackoverflow.com/questions/1038727/how-to-get-browser-width-using-javascript-code
function getWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

function getHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );
}

function defaultSize() {
    const w = Math.max(getWidth() * 3 / 4, 640)
    return w
}

class BasicLinePlotter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
                data: [],
                layout: {width: props.width || defaultSize(), height: props.height || defaultSize() / 2, title: props.title},
                config: {displayModeBar: true}
        }
    }

    render() {
        return (
            <Plot
                data={this.state.data}
                layout={this.state.layout}
                frames={this.state.frames}
                config={this.state.config}
                onInitialized={(figure) => this.setState(figure)}
                onUpdate={(figure) => this.setState(figure)}
            />
        );
    }
}


export default BasicLinePlotter