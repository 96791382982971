import React from "react"
import "./Popup.css"

// https://www.youtube.com/watch?v=i2Yf7JZonB4
const Popup = ({state, setState}) => {
        return (
            <div className={state.active ? "Popup-modal Popup-active" : "Popup-modal"} onClick={() => setState({active: false})}>
                <div className={state.active ? "Popup-modal-content Popup-active" : "Popup-modal-content"}
                     onClick={(e) => e.stopPropagation()}>
                    {state.children}
                </div>
            </div>
        )
}

export default Popup