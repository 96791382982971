import './LocalFileDrawer.css';
import BasicLinePlotter from "../../plotly/BasicLinePlotter";
import LocalFileUploader from "../../file/LocalFileUploader";
import React from "react"

import {getReadableFileSizeString, parseCsvToPlotlyTraces, shallowCloneTraces, hideDataPlot} from "./utils/insight200_parser"

class LocalFileDrawer extends React.Component {
    constructor(props) {
        super(props);
        // create refs to plotters to manage their state
        const refCount = 5 // five plots on the page
        this.plotterRefArray = Array.from(Array(refCount), (_, __) => React.createRef())
        this.i18n_resolver = props.i18n_resolver
    }

    // method accepts raw data from file and draws components
    processCsv(rawText) {
        const dataOriginal = parseCsvToPlotlyTraces(rawText) // parse CSV to Plotly consumable format
        // use shallow copy to prevent sharing state for the plots (but they should share they underlying data)
        // TODO filter data by backend
        const data1 = shallowCloneTraces(dataOriginal)
        const data2 = shallowCloneTraces(dataOriginal)
        const data3 = shallowCloneTraces(dataOriginal)
        const data4 = shallowCloneTraces(dataOriginal)
        const data5 = shallowCloneTraces(dataOriginal)

        const plotters = this.plotterRefArray.map(v => v.current)
        plotters[0].setState(hideDataPlot(data1, ["A_X:", "A_Y:", "A_Z:", "H:"]))
        plotters[1].setState(hideDataPlot(data2, ["R_X:", "R_Y:", "R_Z:"]))
        plotters[2].setState(hideDataPlot(data3, ["Temp:", "Vin:"]))
        plotters[3].setState(hideDataPlot(data4, ["Δ", "Hz avg"]))
        plotters[4].setState(hideDataPlot(data5))
    }

    render() {
        return (
            <div className="FiveGraphicsDrawer">
                { /* File uploader goes first */}
                <div className="FiveGraphicsDrawer-upload-pane">
                    <p>{this.i18n_resolver("drawer_local_file_prompt")}</p>
                    <LocalFileUploader
                        onbegin={(file) => {
                            let fileSizeHumanReadable = getReadableFileSizeString(file.size)
                            const softFileLimit = 5 * 1024 * 1024
                            const hardFileLimit = 50 * 1024 * 1024
                            if (file.size > hardFileLimit) {
                                let errMsg = this.i18n_resolver("drawer_error_file_is_too_big") || "File is too big"
                                throw Error(errMsg)
                            }
                            if (file.size > softFileLimit) {
                                let toAppend = this.i18n_resolver("drawer_prompt_this_may_take_a_while") || " (this may take a while)"
                                fileSizeHumanReadable += toAppend
                            }

                            // show wait div, hide result and error div
                            const waitElement = document.getElementById("local-plotter-array-wait")
                            waitElement.hidden = false
                            waitElement.innerHTML = `<p>Processing file "${file.name}" with size ${fileSizeHumanReadable}.</p>`
                            document.getElementById("local-plotter-array").hidden = true
                            document.getElementById("local-plotter-array-error").hidden = true
                        }}
                        onload={(file, text) => {
                            // process CSV
                            this.processCsv(text)
                            // show result div and hide error and wait div
                            document.getElementById("local-plotter-array-wait").hidden = true
                            document.getElementById("local-plotter-array").hidden = false
                            document.getElementById("local-plotter-array-error").hidden = true
                        }}
                        onerror={(file, error) => {
                            // show error, hide wait and result div
                            document.getElementById("local-plotter-array-wait").hidden = true
                            document.getElementById("local-plotter-array").hidden = true
                            const errElement = document.getElementById("local-plotter-array-error")
                            errElement.hidden = false
                            errElement.innerHTML = `<p>An error occured while uploading "${file.name}":</p> <p>${error}</p>`
                        }}
                    />
                </div>

                <div id="local-plotter-array-wait" className="FiveGraphicsLocalDrawer-local-plotter-array-wait" hidden={true}/>
                <div id="local-plotter-array-error" className="FiveGraphicsLocalDrawer-local-plotter-array-error" hidden={true}/>
                <div id="local-plotter-array" hidden={true}>
                    <BasicLinePlotter ref={this.plotterRefArray[0]}
                                      title={this.i18n_resolver("drawer_plotter_1_caption")}/>
                    <BasicLinePlotter ref={this.plotterRefArray[1]}
                                      title={this.i18n_resolver("drawer_plotter_2_caption")}/>
                    <BasicLinePlotter ref={this.plotterRefArray[2]}
                                      title={this.i18n_resolver("drawer_plotter_3_caption")}/>
                    <BasicLinePlotter ref={this.plotterRefArray[3]}
                                      title={this.i18n_resolver("drawer_plotter_4_caption")}/>
                    <BasicLinePlotter ref={this.plotterRefArray[4]}
                                      title={this.i18n_resolver("drawer_plotter_5_caption")}/>
                </div>
            </div>
        );
    }
}

export default LocalFileDrawer;
