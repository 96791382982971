// https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
export function getReadableFileSizeString(fileSizeInBytes) {
    var i = -1;
    let byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

export function parseCsvToPlotlyTraces(rawText) {
    // Dumb way to parse CSV

    // define time header name
    const timeHeaderName = "Ti:"


    // split CSV by lines
    const lines = rawText.replaceAll("\r\n", "\n").split("\n").filter(s => s.length !== 0).map(s => s.split(','))
    // get header with description of CSV columns
    let header = lines.shift()
    // get header length (e.g. column count)
    const headerLength = header.length

    // find id of time component (should be x-axis)
    let idOfTi = header.findIndex(h => h === timeHeaderName)
    // define array of original traces
    let data = header.map(h => {
        return {x: [], y: [], type: "line", name: h}
    })

    /// define calculatable traces
    let diffTrace = {x: [], y: [], type: "line", name: "Δ"}

    // define bin size to average "delta" (see below)
    const maxDeltaBinSize = 15000
    let avgDiffTrace = {x: [], y: [], type: "line", name: "Hz avg", visible: "legendonly"} // hidden by default example
    var avgQueue = []
    var avgDiff = 0

    // loop over csv values to fill in traces
    let old_x = 0
    let firstIter = true
    for (const line of lines) {
        let x = line[idOfTi]
        // fill in calculatable traces
        if (firstIter) {
            firstIter = false
            old_x = x
        } else {
            const newDelta = x - old_x
            avgQueue.push(newDelta)
            avgDiff += newDelta
            if (avgQueue.length >= maxDeltaBinSize) {
                avgDiff -= avgQueue.shift()
            }
            const windowedAvgDelta = avgDiff / avgQueue.length

            diffTrace.x.push(x)
            diffTrace.y.push(newDelta)

            avgDiffTrace.x.push(x)
            avgDiffTrace.y.push(1000 / windowedAvgDelta)
        }

        // fill in original traces
        for (let i = 0; i < headerLength; i++) {
            // do not put values that are not interpretable as numbers
            if (isNaN(line[i])) {
                continue
            }
            data[i].x.push(x)
            data[i].y.push(line[i])
        }
        old_x = x
    }
    // filter out time component that shouldn't be drawn (function with behaviour f(x) = x)
    const filtered_data = data.filter(line => line.name !== timeHeaderName)
    // push calculatable traces to raw csv traces
    filtered_data.push(diffTrace)
    filtered_data.push(avgDiffTrace)
    // return data to plot: an array of Plotly traces
    return filtered_data
}

// this method is used to split state (traces) between plots. Use with 'parse' method in the same library
export function shallowCloneTraces(plotlyTraces) {
    return plotlyTraces.map(trace => {
        return {x: trace.x, y: trace.y, type: "line", name: trace.name}
    })
}

// this method makes hidden lines of data
export function hideDataPlot(plotlyTraces, enabledTracesTitle) {
    const filtered = plotlyTraces.map(trace => {
        if (!enabledTracesTitle) {
            trace.visible = true
            return trace
        }
        if (enabledTracesTitle.findIndex(v => v === trace.name) === -1) {
            trace.visible = "legendonly"
        } else {
            trace.visible = true
        }
        return trace
    })
    return {data: filtered}
}
