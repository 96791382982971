import React from "react"

import "./FileList.css"
import icon_graphic from '../../resources/icons/stroke_icons/GRAPHIC.svg'
import icon_hyperlink from '../../resources/icons/stroke_icons/HYPERLINK.svg'
import icon_trash from '../../resources/icons/stroke_icons/TRASH BIN.svg'
import {getReadableFileSizeString} from "./fiveGraphicsDrawer/utils/insight200_parser";

/* Uploads result as json array and renders it */
export function uploadFileListPromise(url) {
    // let url = "/api/v1/files/measurement_file/list"
    return fetch(url).then(res => res.json()).then(
        res => {
            if (res.map) {
                return res
            } else {
                console.warn("Json result has no map method", res)
            }
        },
        console.error
    )
}

export const FileList = ({fileList, onDelete}) => {
        const unknownElem = <span className="FileList-unknown-element">unknown</span>
        // define three cells renderer
        const nameRenderer = (fileAsJson, key) => {
            let openFileIdDrawerUrl = "/graphic/id?file_id=" + fileAsJson['file_meta']['id']
            if (fileAsJson['name']) {
                return <div key={key} className="FileList-elem">
                    <a href={openFileIdDrawerUrl}>
                         <span>{fileAsJson['name']}</span>
                    </a>
                </div>
            }
            return unknownElem
        }
        const destinationRenderer = (fileAsJson, key) => {
            if (fileAsJson['file_meta'] && fileAsJson['file_meta']['file_type']) {
                return <div key={key}
                            className="FileList-elem">
                    <span>{fileAsJson['file_meta']['file_type']['description']}</span></div>
            }
            return unknownElem
        }
        const sizeRenderer = (fileAsJson, key) => {
            if (fileAsJson['file_meta'] && fileAsJson['file_meta']['size']) {
                return <div key={key}
                            className="FileList-elem">
                    <span>{getReadableFileSizeString(fileAsJson['file_meta']['size'])}</span></div>
            }
            return unknownElem
        }
        const dateRenderer = (fileAsJson, key) => {
            console.log("fileAsJson", fileAsJson)
            if (fileAsJson['file_meta'] && fileAsJson['file_meta']['addition_date']) {
                return <div key={key}
                            className="FileList-elem">
                    <span>{fileAsJson['file_meta']['addition_date']}</span></div>
            }
            return unknownElem
        }
        const urlRenderer = (fileAsJson, key) => {
            let openFileIdDrawerUrl = "/graphic/id?file_id=" + fileAsJson['file_meta']['id']
            let downloadFileUrl = `/api/v1/files/measurement_file/${fileAsJson['file_meta']['id']}/stream`
            let deleteFileUrl = `/api/v1/files/measurement_file/${fileAsJson['file_meta']['id']}`
            return <div key={key}
                        className="FileList-elem FileList-small-urls">
                <span><a href={openFileIdDrawerUrl}><img className="FileList-small-icons" src={icon_graphic} alt="stroke icons graphic"/></a></span>
                &nbsp;
                <span><a href={downloadFileUrl}><img className="FileList-small-icons" src={icon_hyperlink} alt="stroke icons hyperlink"/></a></span>
                &nbsp;
                <span><a href="#" onClick={() => {
                    fetch(deleteFileUrl, {method: 'DELETE'}).then(
                        result => {onDelete(fileAsJson)}
                    )

                }
                }><img className="FileList-small-icons" src={icon_trash} alt="stroke icons trash"/></a></span>
            </div>
        }
        const itemRenderer = (fileAsJson, id) => {
            return [
                nameRenderer(fileAsJson, "name-" + id),
                destinationRenderer(fileAsJson, "dest-" + id),
                sizeRenderer(fileAsJson, "size-" + id),
                dateRenderer(fileAsJson, "date-" + id),
                urlRenderer(fileAsJson, "url-" + id)
            ]
        }

        // main return
        return (<div className="FileList-root">
            {/* header: name, type-desc, size, date etc.*/}
            <div className="FileList-header-elem">
                <span>name</span>
            </div>
            <div className="FileList-header-elem">
                <span>file type description</span>
            </div>
            <div className="FileList-header-elem">
                <span>size</span>
            </div>
            <div className="FileList-header-elem">
                <span>date</span>
            </div>
            <div className="FileList-header-elem">
                <span></span>
            </div>
            {/* Body: flat mapping array of rows */}
            {fileList.flatMap((fj, i) => itemRenderer(fj, i))}
        </div>)
}

export default FileList