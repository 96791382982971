import React, {useEffect, useState} from "react"
import "./ProfilePage.css"
import {getPagePosition} from "../../../utils/positioning";
import Popup from "../../popup/Popup";

// RECOMMENDED to use some css framework, like this:
// https://purecss.io/

// P.S. this is quite not reusable because global ID's are using

export const ProfilePage = () => {
    let [userState, setUserState] = useState(
        {
            name: null,
            family_name: null,
            patronymic_name: null,
            biography: null,
            email: null,
            id_country: null,
            id_city: null,
            react_form_uploaded: false
        }
    )

    // add onChange for all corresponding fields
    let handleInputChange = (e) => {
        setUserState(p => {
            return {
                ...p,
                [e.target.name]: e.target.value
            }
        });
    }

    // upload data from backend
    if (!userState.react_form_uploaded) {
        fetch("/api/v1/user").then(r => r.json()).then(
            r => {
                setUserState(p => {
                    return {
                        ...p,
                        ...r,
                        react_form_uploaded: true,
                    }
                })

                document.getElementById("country").value = r.country.name
                document.getElementById("city").value = r.city.name
                setCityState(p => {
                    return {
                        ...p,
                        selected: r.city
                    }
                })
                setCountryState(p => {
                    return {
                        ...p,
                        selected: r.country
                    }
                })
            },
            console.error
        )
    }

    let [popupState, setPopupState] = useState(
        {
            active: false,
            children: <div/>
        }
    )
    let [cityState, setCityState] = useState(
        {
            focused: false,
            hidden: true,
            elements: [],
            selected: {}, // TODO move to userState
            style: {}
        })

    let [countryState, setCountryState] = useState(
        {
            focused: false,
            hidden: true,
            elements: [],
            selected: {}, // TODO move to userState
            style: {}
        }
    )


    let countryEventProcessor = (event, focus, blur) => {
        let userInput = event.target.value
        if (focus) {
            setCountryState(p => {
                return {...p, focused: true, hidden: true}
            })
        }
        if (blur) {
            // this is done in order to process other events
            // TODO find more beautiful solution
            setTimeout(() => {
                    setCountryState(p => {
                        return {...p, focused: false, hidden: true}
                    })
                }
                , 200)
        }

        if ((focus || countryState.focused) && !blur && userInput) {
            let [x, y, h, w] = getPagePosition(event.target)
            // fetch list from backend
            let url = `/api/v1/geo/countries?prefix=${userInput}`
            setCountryState(p => {
                return {
                    ...p,
                    hidden: !p.elements,
                }
            })
            // TODO -- either make semaphore, or upload whole DB to user :D
            fetch(url).then(rsp => rsp.json()).then(
                values => {
                    setCountryState(p => {
                        return {
                            ...p,
                            elements: values,
                            hidden: false,
                            style: {
                                "position": "absolute",
                                top: y + h,
                                left: x,
                                maxHeight: "50%",
                                width: w
                            },
                        }
                    })
                },
                console.log
            )
        }
    }
    let cityEventProcessor = (event, focus, blur) => {
        let userInput = event.target.value
        if (focus) {
            setCityState(p => {
                return {...p, focused: true, hidden: true}
            })
        }
        if (blur) {
            // TODO find more beautiful solution
            setTimeout(() => {
                    setCityState(p => {
                        return {...p, focused: false, hidden: true}
                    })
                }
                , 200)
        }

        if ((focus || cityState.focused) && !blur && userInput) {
            let [x, y, h, w] = getPagePosition(event.target)
            // fetch list from backend
            let url = `/api/v1/geo/cities?prefix=${userInput}`
            if (countryState.selected && countryState.selected.id) {
                url += `&country_id=${countryState.selected.id}`
            }
            setCityState(p => {
                return {
                    ...p,
                    hidden: !p.elements,
                }
            })
            // TODO -- either make semaphore, or upload whole DB to user :D
            fetch(url).then(rsp => rsp.json()).then(
                values => {
                    setCityState(p => {
                        return {
                            ...p,
                            elements: values,
                            hidden: false,
                            style: {
                                "position": "absolute",
                                top: y + h,
                                left: x,
                                maxHeight: "50%",
                                width: w
                            },
                        }
                    })
                },
                console.log
            )
        }
    }


    // set once event listener, that hides drop downs when resized
    // TODO replace with useEffect
    let [singleton, setSingleton] = useState({
        once: false
    })
    if (!singleton.once) {
        // executed only once
        setSingleton({
            once: true
        })
        window.addEventListener('resize', function (event) {
            setCityState(p => {
                return {...p, hidden: true}
            })
            setCountryState(p => {
                return {...p, hidden: true}
            })
        }, true);
    }

    return (
        <form className="pure-form pure-form-aligned">
            <fieldset>
                <div className="pure-control-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" name="given_name" placeholder="Name" value={userState.given_name}
                           onChange={handleInputChange} required/>
                    <span className="pure-form-message-inline">This is a required field.</span>
                </div>
                <div className="pure-control-group">
                    <label htmlFor="family_name">Surname</label>
                    <input type="text" id="family_name" name="family_name" placeholder="Surname"
                           value={userState.family_name} onChange={handleInputChange} required/>
                    <span className="pure-form-message-inline">This is a required field.</span>
                </div>
                <div className="pure-control-group">
                    <label htmlFor="patronymic_name">Patronymic name</label>
                    <input type="text" id="patronymic_name" name="patronymic_name" placeholder="Patronymic name"
                           value={userState.patronymic_name}
                           onChange={handleInputChange}/>
                </div>
                <div className="pure-control-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" name="email" placeholder="Email Address" value={userState.email}
                           onChange={handleInputChange}/>
                </div>
                <div className="pure-control-group">
                    <label htmlFor="biography">Biography</label>
                    <textarea id="biography" name="biography" placeholder="Enter something here..."
                              value={userState.biography}
                              onChange={handleInputChange}/>
                </div>
                <div className="pure-control-group">
                    <label htmlFor="country">Country</label>
                    <input type="text" id="country" placeholder="Start entering country"
                           autoComplete="off"
                           onChange={e => countryEventProcessor(e, false, false)}
                           onFocus={e => countryEventProcessor(e, true, false)}
                           onBlur={e => countryEventProcessor(e, false, true)}
                    />
                    <span className="pure-form-message-inline">Click on pop up entry to commit your choice</span>

                    <div style={countryState.style} hidden={countryState.hidden}
                         className="pure-menu pure-menu-scrollable ProfilePage-solid-combo-popup-bg">
                        <ul className="pure-menu-list">
                            {
                                countryState.elements.map(element =>
                                    <li key={"country" + element.id} className="pure-menu-item">
                                        <a href="#" className="pure-menu-link" onClick={
                                            event => {
                                                document.getElementById("country").value = element.name
                                                setCountryState(p => {
                                                    return {
                                                        ...p,
                                                        selected: element,
                                                        focused: false,
                                                        hidden: true,
                                                    }
                                                })
                                                setUserState(p => {
                                                        return {
                                                            ...p,
                                                            id_country: element.id,
                                                        }
                                                    }
                                                )
                                                if (cityState.selected && cityState.selected.country &&
                                                    cityState.selected.country.id !== element.id) {
                                                    document.getElementById("city").value = ""
                                                    setCityState(p => {
                                                        return {
                                                            ...p,
                                                            selected: {},
                                                        }
                                                    })
                                                    setUserState(p => {
                                                            return {
                                                                ...p,
                                                                id_city: null,
                                                            }
                                                        }
                                                    )
                                                }
                                            }
                                        }>{element.emoji} {element.name}</a>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
                <div className="pure-control-group">
                    <label htmlFor="city">City</label>
                    <input type="text" id="city" placeholder="Start entering city"
                           autoComplete="off"
                           onChange={e => cityEventProcessor(e, false, false)}
                           onFocus={e => cityEventProcessor(e, true, false)}
                           onBlur={e => cityEventProcessor(e, false, true)}
                    />
                    <span className="pure-form-message-inline">Click on pop up entry to commit your choice</span>

                    <div style={cityState.style} hidden={cityState.hidden}
                         className="pure-menu pure-menu-scrollable ProfilePage-solid-combo-popup-bg">
                        <ul className="pure-menu-list">
                            {
                                cityState.elements.map(element =>
                                    <li key={"city" + element.id} className="pure-menu-item">
                                        <a href="#" className="pure-menu-link" onClick={
                                            event => {
                                                document.getElementById("country").value = element.country.name
                                                document.getElementById("city").value = element.name
                                                setCountryState(p => {
                                                    return {
                                                        ...p,
                                                        selected: element.country,
                                                    }
                                                })
                                                setCityState(p => {
                                                    return {
                                                        ...p,
                                                        selected: element,
                                                        focused: false,
                                                        hidden: true,
                                                    }
                                                })
                                                setUserState(p => {
                                                        return {
                                                            ...p,
                                                            id_country: element.country_id,
                                                            id_city: element.id,
                                                        }
                                                    }
                                                )
                                                console.log("cityState", element, cityState)
                                            }
                                        }>{element.country.emoji} {element.name}</a>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
                <div className="pure-controls">
                    <button type="submit" className="pure-button pure-button-primary"
                            onClick={() => {
                                fetch('/api/v1/user', {
                                    method: 'post',
                                    headers: {
                                        'Accept': 'application/json, text/plain, */*',
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(userState)
                                })
                                    .then(res => res.json())
                                    .then(
                                        res => window.location.reload(),
                                        err => setPopupState({
                                            active: true,
                                            children: `An error occured: ${JSON.stringify(err)}`
                                        })
                                    );
                            }}>
                        Save
                    </button>

                    <Popup state={popupState} setState={setPopupState}/>
                </div>
            </fieldset>
        </form>
    )
}

export default ProfilePage