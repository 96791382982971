import './App.css';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom"
import React from "react"
import LocalFileDrawer from "./ui/pages/fiveGraphicsDrawer/LocalFileDrawer";
import FileIdDrawer from "./ui/pages/fiveGraphicsDrawer/FileIdDrawer";
import MenuButton from "./ui/buttons/MenuButton";
import {SocialMediaInfo, ContactsInfo} from "./ui/sections/SocialMediaInfo";
import SpaceIsCloserBackground from "./resources/backgrounds/doug-wade-space-is-closer-than-you-think.jpg";
import Insight200PlusImage from "./resources/images/Insight200Plus.png"
import FileManager from "./ui/pages/FileManager";
import {resolve_i18n_string} from "./i18n/i18n_provider"

import gria_logo from './resources/logo/KOROLEV_Gria_logo_v2_2_Re02.png'
import ProfilePage from "./ui/pages/profile/ProfilePage";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            userCreds: null,
            error: null,
        };
    }

    componentDidMount() {
        let heartbeatUrl = "/api/v1/user"
        fetch(heartbeatUrl)
            .then(
                (result) => {
                    if (result.status >= 200 && result.status < 300) {
                        return result.json()
                    } else {
                        return new Promise((resolve, reject) => reject(Error("Not successful response")))
                    }
                })
            .then(
                (json) => {
                    this.setState({
                        loggedIn: true,
                        userCreds: json,
                        error: null
                    });
                },
                (error) => {
                    this.setState({
                        loggedIn: false,
                        userCreds: null,
                        error: error,
                    });
                }
            )
    }

    render() {
        return (
            <div className="App">
                <Router>
                    <header className="App-header App-space-background">
                        <div className="App-logo-holder">
                            <img src={gria_logo} className="App-logo" alt="gria_logo"/>
                        </div>
                    </header>
                    <div className="App-body">
                        <div className="App-space-background">
                            {/* for logged in users menu buttons */}
                            <div className={"App-menu " + (this.state.loggedIn ? "" : "hidden")}>
                                <Link className="App-no-decoration" to="/file_manager">
                                    <MenuButton label={resolve_i18n_string("side_button_label_file_manager")}/>
                                </Link>
                                <Link className="App-no-decoration" to="/profile">
                                    <MenuButton label={resolve_i18n_string("side_button_label_profile")}/>
                                </Link>
                                <hr/>
                            </div>
                            {/* other visible buttons */}
                            <div className="App-menu">
                                <Link className="App-no-decoration" to="/">
                                    <MenuButton label={resolve_i18n_string("side_button_label_about")}/>
                                </Link>
                                <a href="http://wiki.gria.tech" target='_blank' rel="noopener noreferrer" style={{all: 'unset'}}>
                                    <MenuButton label={resolve_i18n_string("side_button_label_electronics")}/>
                                </a>
                                <Link className="App-no-decoration" to="/graphic">
                                    <MenuButton label={resolve_i18n_string("side_button_label_data_tool")}/>
                                </Link>
                                <Link className="App-no-decoration" to="/contacts">
                                    <MenuButton label={resolve_i18n_string("side_button_label_contacts")}/>
                                </Link>
                            </div>
                        </div>
                        <div className="App-content-with-footer">
                            <div className="App-content">
                                {/* This route is responsible for file uploading */}
                                <Route path="/file_manager">
                                    <div hidden={!this.state.loggedIn}>
                                        <FileManager/>
                                    </div>
                                </Route>

                                {/* Profile page */}
                                <Route path="/profile">
                                    <div hidden={!this.state.loggedIn}>
                                        <ProfilePage />
                                    </div>
                                </Route>

                                {/* This route is responsible for main page */}
                                <Route exact path="/">
                                    <h3 className="App-centering">{resolve_i18n_string("about_gria_tech_h3")}</h3>

                                    <p>{resolve_i18n_string("about_gria_tech_p1")}</p>
                                    <p>{resolve_i18n_string("about_gria_tech_p2")}</p>
                                    <p>{resolve_i18n_string("about_gria_tech_p3")}</p>
                                    <p>{resolve_i18n_string("about_gria_tech_p4")}</p>
                                    <p>{resolve_i18n_string("about_gria_tech_p5")}</p>

                                    <div className="App-centering">
                                        <img alt="Space is closer than you think" src={SpaceIsCloserBackground}/>
                                    </div>
                                </Route>

                                {/* This route is responsible for five graphics */}
                                <Route path="/electronics">
                                    <div className="App-centering">
                                        <h2>{resolve_i18n_string("electronics_insight_200_plus_h2")}</h2>
                                        <h4>{resolve_i18n_string("electronics_rocket_data_logger_h4")}</h4>
                                        <img alt='Gria Tech "Insight 200+ flight recorder' src={Insight200PlusImage}/>
                                        <h5>{resolve_i18n_string("electronics_high_speed_easy_to_use_h5")}</h5>
                                    </div>
                                    <p>{resolve_i18n_string("electronics_p1")}</p>
                                    <table align="center" cellSpacing="15" cellPadding="0">
                                        <tbody>
                                        <tr>
                                            <td>{resolve_i18n_string("electronics_params_table_td_1_1")}</td>
                                            <td/>
                                            <td>{resolve_i18n_string("electronics_params_table_td_1_2")}</td>
                                        </tr>
                                        <tr>
                                            <td>{resolve_i18n_string("electronics_params_table_td_2_1")}</td>
                                            <td/>
                                            <td>{resolve_i18n_string("electronics_params_table_td_2_2")}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </Route>

                                {/* This route is responsible for five graphics
                                   'exact' parameter is needed to avoid collision with /graphic/id */}
                                <Route exact path="/graphic">
                                    <LocalFileDrawer i18n_resolver={resolve_i18n_string}/>
                                </Route>

                                {/* This route is responsible for five graphics */}
                                <Route path="/graphic/id">
                                    <FileIdDrawer i18n_resolver={resolve_i18n_string}/>
                                </Route>


                                {/* Contacts section */}
                                <Route path="/contacts">
                                    <h3>{resolve_i18n_string("contacts_h3")}</h3>
                                    <p>{resolve_i18n_string("contacts_p1")}</p>
                                    <p>{resolve_i18n_string("contacts_p2")}</p>

                                    <ContactsInfo/>
                                </Route>

                                {/* Privacy policy info */}
                                <Route exact path="/privacy">
                                    <ul>
                                        <li>
                                            <a className="App-no-decoration"  href="/privacy_ru.pdf">{resolve_i18n_string("privacy_a_russian")}</a>
                                        </li>
                                        <li>
                                            <a className="App-no-decoration"  href="/privacy_en.pdf">{resolve_i18n_string("privacy_a_english")}</a>
                                        </li>
                                    </ul>
                                    <br />
                                </Route>
                            </div>
                            {/* Content finalizer as footer */}
                            <footer className="App-footer">
                                <SocialMediaInfo caption={resolve_i18n_string("footer_gria_tech_social")}/>
                            </footer>
                        </div>
                    </div>
                </Router>
            </div>
        )
    }
}

export default App;
