import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

// https://react-dropzone.netlify.app/
export const DropzoneS3FileUploader = ({onUpload}) => {
  // specify upload params and url for your files
  const getUploadParams = ({ meta }) => { return { url: '/api/v1/files/measurement_file/' } }

  // called every time a file's `status` changes
  const handleChangeStatus = ({meta, file, remove}, status) => {
    console.log(status, meta, file)
    if (status === "done") {
      if (typeof onUpload === 'function') {
        onUpload(meta)
      } else {
        console.error("Submit is not a function :(")
      }
      remove()
    }
  }

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      accept="text/plain,text/csv"
    />
  )
}