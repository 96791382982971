let strings_en = require('./strings.en.json');
let strings_ru = require('./strings.ru.json');

function resolve_locale_string(key, lang) {
    if (lang.toLowerCase().startsWith("en") && key in strings_en) {
        return {string: strings_en[key], ok: true}
    }
    if (lang.toLowerCase().startsWith("ru") && key in strings_ru) {
        return {string: strings_ru[key], ok: true}
    }
    return {string: "", ok: false}
}

export function resolve_i18n_string(key) {
    let lang = navigator.language
    console.info(`use locale: ${lang} from diversity of ${navigator.languages}`)

    let resolveLocaleRes = resolve_locale_string(key, lang)
    if (resolveLocaleRes.ok) {
        return resolveLocaleRes.string
    }

    console.warn(`no locale string for key '${key}', try to resolve English version by default`)
    if (strings_en[key] && key in strings_en) {
        return strings_en[key]
    }

    console.warn(`unknown key '${key}' for locale ${lang}`)
    return null
}