import './FileIdDrawer.css';
import BasicLinePlotter from "../../plotly/BasicLinePlotter";
import React from "react"
import {hideDataPlot, parseCsvToPlotlyTraces, shallowCloneTraces} from "./utils/insight200_parser";


class FileIdDrawer extends React.Component {
    constructor(props) {
        super(props);
        // create refs to plotters to manage their state
        const refCount = 5 // five plots on the page
        this.plotterRefArray = Array.from(Array(refCount), (_, __) => React.createRef())
        this.i18n_resolver = props.i18n_resolver
    }

    // method accepts raw data from file and draws components
    processCsv(rawText) {
        const dataOriginal = parseCsvToPlotlyTraces(rawText) // parse CSV to Plotly consumable format
        // use shallow copy to prevent sharing state for the plots (but they should share they underlying data)
        // TODO filter data by backend
        const data1 = shallowCloneTraces(dataOriginal)
        const data2 = shallowCloneTraces(dataOriginal)
        const data3 = shallowCloneTraces(dataOriginal)
        const data4 = shallowCloneTraces(dataOriginal)
        const data5 = shallowCloneTraces(dataOriginal)

        const plotters = this.plotterRefArray.map(v => v.current)
        plotters[0].setState(hideDataPlot(data1, ["A_X:", "A_Y:", "A_Z:", "H:"]))
        plotters[1].setState(hideDataPlot(data2, ["R_X:", "R_Y:", "R_Z:"]))
        plotters[2].setState(hideDataPlot(data3, ["Temp:", "Vin:"]))
        plotters[3].setState(hideDataPlot(data4, ["Δ", "Hz avg"]))
        plotters[4].setState(hideDataPlot(data5))
    }

    setInit() {
        // show wait div, hide result and error div
        const waitElement = document.getElementById("fileid-plotter-array-wait")
        waitElement.hidden = false
        waitElement.innerHTML = `<p>Flying to the moon!</p>`
        document.getElementById("fileid-plotter-array").hidden = true
        document.getElementById("fileid-plotter-array-error").hidden = true
    }

    setLoaded() {
        // show result div and hide error and wait div
        document.getElementById("fileid-plotter-array-wait").hidden = true
        document.getElementById("fileid-plotter-array").hidden = false
        document.getElementById("fileid-plotter-array-error").hidden = true
    }

    setError(error) {
        // show error, hide wait and result div
        document.getElementById("fileid-plotter-array-wait").hidden = true
        document.getElementById("fileid-plotter-array").hidden = true
        const errElement = document.getElementById("fileid-plotter-array-error")
        errElement.hidden = false
        errElement.innerHTML = `<p>An error occured:</p> <p>${error}</p>`
    }

    componentDidMount() {
        this.setInit()
        // get ID
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        let id = params.file_id
        if (!id) {
            this.setError(this.i18n_resolver("drawer_error_file_id_should_be_specified") || "File id should be specified")
        }
        // get file path
        let getFilePath = `/api/v1/files/measurement_file/${id}/stream`
        fetch(getFilePath)
            .then(resp => {
                if (resp.status === 200) {
                    return resp.text()
                } else {
                    return new Promise((reslove, reject) => reject(`${resp.status} ${resp.statusText}`))
                }
            })
            .then(resp => {
                // process CSV
                this.processCsv(resp)
                this.setLoaded()
            }, error => {
                let msgPrefix = this.i18n_resolver("drawer_error_downloading_file_prefix") || "Error downloading file: "
                this.setError(msgPrefix + error)
            })
    }

    render() {
        return (
            <div className="FiveGraphicsDrawer">
                <div id="fileid-plotter-array-wait" className="FiveGraphicsDrawerFileId-fileid-plotter-array-wait" hidden={true}/>
                <div id="fileid-plotter-array-error" className="FiveGraphicsDrawerFileId-fileid-plotter-array-error" hidden={true}/>
                <div id="fileid-plotter-array">
                    <BasicLinePlotter ref={this.plotterRefArray[0]}
                                      title={this.i18n_resolver("drawer_plotter_1_caption")}/>
                    <BasicLinePlotter ref={this.plotterRefArray[1]}
                                      title={this.i18n_resolver("drawer_plotter_2_caption")}/>
                    <BasicLinePlotter ref={this.plotterRefArray[2]}
                                      title={this.i18n_resolver("drawer_plotter_3_caption")}/>
                    <BasicLinePlotter ref={this.plotterRefArray[3]}
                                      title={this.i18n_resolver("drawer_plotter_4_caption")}/>
                    <BasicLinePlotter ref={this.plotterRefArray[4]}
                                      title={this.i18n_resolver("drawer_plotter_5_caption")}/>
                </div>
            </div>
        );
    }
}

export default FileIdDrawer;
